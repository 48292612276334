import { Pagination } from "@mui/material";
import styled from "styled-components";
  // background-color: #FAF9F6;
  // box-shadow: 0px 0px 20px rgba(48, 163, 221, 0.2);

export const MainCardContainer = styled.div`
  border-radius: 15px;
  background-color: #FFFFFF;
  max-width: 904px;
  box-shadow: 0px -3.792px 151.692px 0px rgba(4, 191, 123, 0.07), 0px -1.584px 63.373px 0px rgba(4, 191, 123, 0.05), 0px -0.847px 33.882px 0px rgba(4, 191, 123, 0.04), 0px -0.475px 18.994px 0px rgba(4, 191, 123, 0.04), 0px -0.252px 10.088px 0px rgba(4, 191, 123, 0.03), 0px -0.105px 4.198px 0px rgba(4, 191, 123, 0.02);
`
export const ProfileCardContainer = styled.div`
  border-radius: 15px;
  background-color: #FFFFFF;
  max-width: 904px;
  box-shadow: 0px 0px 20px rgba(48, 163, 221, 0.2);
`
export const MainCardTitle = styled.div<{withSubtext?: boolean}>`
  border-radius: 15px 15px 0px 0px;
  background-color: #012626;
  min-height: 76px;
  padding: 12px 60px;
  display: flex;
${p => p.withSubtext ? `flex-direction: column` : 'align-items: center'};
`

export const MainCardContent = styled.div`
  padding: 32px 60px;
  @media (max-width: 700px) {
    padding: 32px 12px;
  }
`

export const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: '#9A9DA5',
    backgroundColor: '#CEF2D7',
  },
  '& .Mui-selected': {
    backgroundColor: '#04BF7B!important',
    color: '#FAF9F6',
  },
  '& .MuiPaginationItem-page:hover': {
    backgroundColor: '#04BF7B',
    color: '#FAF9F6',
  },
  '& .MuiPaginationItem-previousNext': {
    backgroundColor: 'transparent',
    color: '#FAF9F6',
  },
  '& .MuiPaginationItem-previousNext:hover': {
    backgroundColor: 'transparent',
    color: '#FAF9F6',
  },
  '& .MuiPaginationItem-ellipsis': {
    backgroundColor: 'transparent',
  }
}));