import React, { FC, useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ButtonNavActive from './components/ButtonNavActive';
import { AppContext } from '../context';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, ListItemButton, ListItemIcon, ListSubheader } from '@mui/material';
import Swal from "sweetalert2";
import { IoShareSocialOutline } from 'react-icons/io5';
import { IoIosPower } from 'react-icons/io';
import PumpJuiceLogo from "../assets/pomjuice_twoColors.svg";
import ModalShareProfile from '../pages/private/Share/ModalShareProfile';

type UserProps = {
  firstName?: string;
  lastName?: string;
  startupId?: number | string;
  profileImage?: string;
};

const NavBar: FC<UserProps> = ({
  firstName,
  lastName,
  startupId,
  profileImage,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isOnboarding, isSharingDisabled, dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);

  const modalIsOpen = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handlerLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDrawerOpen(false)
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showCancelButton: true,

      customClass: {
        confirmButton: "swal2-confirm btn btn-primary",
        cancelButton: "swal2-cancel btn btn-default",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: "CLOSE_AUTH" });
        navigate("/login", { replace: true });
        localStorage.clear();
      }
    });
  };

  return (
    <>
      <AppBar sx={{ backgroundColor: '#012626' }} position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}  PaperProps={{
    sx: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '36px 12px 24px 12px',
      borderRadius: '0px 50px 50px 0px'
    },
  }}>
        <List
         subheader={
          <ListSubheader component="div">
            <img
              src={PumpJuiceLogo}
              className="sidebar__logo_img"
              alt="PumpJuiceLogo"
              onClick={() => navigate("/")}
            />
          </ListSubheader>
        }
        >

      <ListItemButton>
          <ListItemIcon>
            <Avatar
              sx={{
                width: 48,
                height: 48,
                backgroundColor: "rgba(105, 226, 142, 0.6)",
              }}
              alt={firstName}
              src={profileImage}
              />
          </ListItemIcon>
          <ListItemText primary={firstName ? firstName : "-"} />
        </ListItemButton>
        <hr style={{ width: "90%" }} />
        <ButtonNavActive
          navRoute={"/dashboard"}
          title={"Dashboard"}
          isActive={location.pathname.includes("/dashboard")}
          />
        <ButtonNavActive
          navRoute={
            isOnboarding ? "/startup-profile" : "/startup-profile/UserProfile"
          }
          title={"Startup Page"}
          isActive={location.pathname.includes("/startup-profile")}
          />
        <ButtonNavActive
          navRoute={"/profile"}
          title={"Profile"}
          isActive={location.pathname.includes("/profile")}
          />
        {!isOnboarding && (
          <ButtonNavActive
          navRoute={"/fundraising"}
          title={"Capital"}
          isActive={location.pathname.includes("/fundraising")}
          />
        )}
      </List>
      <List>
        <ListItemButton
          onClick={!isSharingDisabled() ? modalIsOpen : undefined}
          disabled={isSharingDisabled()}
          sx={{
            ":hover": {
              backgroundColor: !isSharingDisabled()
                ? "rgba(231, 248, 235, 1)"
                : "initial",
              borderRadius: "6px",
            },
            cursor: isSharingDisabled() ? "not-allowed" : "pointer",
            opacity: isSharingDisabled() ? 0.5 : 1,
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                bgcolor: "rgba(231, 248, 235, 1)",
                borderRadius: "10px",
              }}
            >
              <IoShareSocialOutline color="rgba(4, 191, 123, 1)" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              ":hover": {
                color: !isSharingDisabled()
                  ? "rgba(4, 191, 123, 1)"
                  : "initial",
              },
            }}
            primary={"Share"}
          />
        </ListItemButton>
        <ButtonNavActive
          navRoute={"/settings"}
          title={"Settings"}
          isActive={location.pathname.includes("/settings")}
        />
        <ListItemButton
          onClick={(e) => handlerLogout(e)}
          sx={{
            ":hover": {
              backgroundColor: "rgba(231, 248, 235, 1)",
              borderRadius: "6px",
            },
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{ bgcolor: "rgba(231, 248, 235, 1)", borderRadius: "10px" }}
            >
              <IoIosPower color="rgba(4, 191, 123, 1)" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              ":hover": {
                color: "rgba(4, 191, 123, 1)",
              },
            }}
            primary={"Logout"}
          />
        </ListItemButton>
      </List>
      </Drawer>
      <ModalShareProfile
        modalIsOpen={isOpen}
        closeModal={closeModal}
        startupId={startupId}
      />
    </>
  );
};

export default NavBar;
