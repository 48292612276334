import React, { FC, useContext, useState } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { BiSolidPencil } from "react-icons/bi";
import CompanyDetails from "../ModalChoice/Modals/CompanyDetailsInnerModal";
import { TbRefresh } from "react-icons/tb";
import Swal from "sweetalert2";
import { uploadCompanyLogo } from "../../../../../helpers/Api";
import { AppContext } from "../../../../../context";
import MainCard from "../../../../../components/components/MainCard";
import StartupLogo from "../../../../../assets/startup_logo.svg";
import { Paragraph } from "../../../../../styled/Paragraph";
import { IndustryOptionItem } from "../../../../../types/startup";

interface CardChoosingProps {
  location: string | null | undefined;
  name: string | null | undefined;
  industry: IndustryOptionItem | null | undefined;
  employeeCount: string | null | undefined;
  stage: string | null | undefined;
  websiteUrl: string | null | undefined;
  userId: number | string | null | undefined;
  imageUrl: string | null | undefined;
  isReadOnly?: boolean;
}

const CardEssentialInformation: FC<CardChoosingProps> = ({
  location,
  name,
  industry,
  employeeCount,
  stage,
  websiteUrl,
  userId,
  imageUrl,
  isReadOnly = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const { getStartupProfile } = useContext(AppContext);
  const startupId = userId?.toString();

  const openModal = () => {
    if (!isReadOnly) {
      setModalIsOpen(true);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    getStartupProfile(); // Fetch the updated profile after closing the modal
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setLocalLoading(true);
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      try {
        await uploadCompanyLogo(startupId, formData);
        setLocalLoading(false); // Stop loading animation
        getStartupProfile(); // Fetch data again to update the profile
      } catch (error) {
        Swal.fire("Oops!", "Error uploading profile picture", "error");
        Swal.fire({
          title: "Oops",
          text: `Looks like there is something wrong <br />
            `,
          icon: "error",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        setLocalLoading(false); // Stop loading animation on error
      }
    }
  };

  return (
    <Box
      mb={0}

      // sx={{
      //   margin: "70px 0px",
      //   display: "flex",
      //   flexDirection: "column",
      //   gap: 4,
      // }}
    >
      <MainCard title="Basic Information" subtext="Required *">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // mb: 2,
            position: "relative",
          }}
        >
          <Paragraph fontSize={18} fontWeight="500">
            Logo:
          </Paragraph>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              style={{
                maxWidth: "210px",
                width: "100%",
                borderRadius: "5px",
                padding: "0px",
              }}
              src={imageUrl || StartupLogo}
              alt="Startup Logo"
            />
            {!isReadOnly && (
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {!localLoading ? (
                  <>
                    <button
                      onClick={() => {
                        document
                          .getElementById("profile-picture-upload")
                          ?.click();
                      }}
                      className="profile__input_button"
                      style={{
                        width: "70px",
                      }}
                    >
                      <TbRefresh size={24} />
                    </button>
                    <input
                      id="profile-picture-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </>
                ) : (
                  <CircularProgress size={20} />
                )}
              </Box>
            )}
          </Box>
          {!isReadOnly && (
            <Box
              sx={{
                position: "absolute",
                top: "8px",
                right: "8px",
              }}
            >
              <button
                onClick={openModal}
                className="button__edit color__active"
                style={{
                  backgroundColor: "#04BF7B",
                  borderRadius: "8px",
                  padding: "8px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                }}
              >
                <BiSolidPencil size={22} />
              </button>
            </Box>
          )}
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Company:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400">
                {name}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Location:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400">
                {location}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Industry:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400">
                {industry?.name}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Employees:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400">
                {employeeCount}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Stage:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400">
                {stage}
              </Paragraph>
            </Grid>
            <Grid item xs={4}>
              <Paragraph fontSize={18} fontWeight="400">
                Website:
              </Paragraph>
            </Grid>
            <Grid item xs={8}>
              <Paragraph fontSize={18} fontWeight="400" $wordWrap="break-word">
                {websiteUrl}
              </Paragraph>
            </Grid>
          </Grid>
        </Box>
      </MainCard>
      <CompanyDetails
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        name={name}
        location={location}
        industrySelected={industry}
        employeeCountSelected={employeeCount}
        stageSelected={stage}
        websiteUrl={websiteUrl}
        userId={userId}
      />
    </Box>
  );
};

export default CardEssentialInformation;
