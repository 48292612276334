import React, { FC, useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Modal from "react-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";

//Selectors Collection
import { IndustryCollection } from "../../../../../../helpers/IndustriesSelector";
import { EmployeeCollection } from "../../../../../../helpers/EmployeeSelector";
import { StageCollection } from "../../../../../../helpers/StageSelector";
//API
import { editStartupProfile, getIndustries } from "../../../../../../helpers/Api";
import { IndustryOptionItem } from "../../../../../../types/startup";

interface StartupInputProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  name: string | null | undefined;
  location: string | null | undefined;
  industrySelected: IndustryOptionItem | null | undefined;
  employeeCountSelected: string | null | undefined;
  stageSelected: string | null | undefined;
  websiteUrl: string | null | undefined;
  userId: number | string | null | undefined;
}

interface StartupFormProfileProps {
  name: string;
  description: string;
  location: string;
  industry: { id: string; name: string }[];
  employeeCount: { label: string; value: string }[];
  stage: { label: string; value: string }[];
  websiteUrl: string;
}

export type IndustriesSelector = {
  id: string;
  name: string;
};

type EmployeeSelector = {
  value: string;
  label: string;
};

type StageSelector = {
  value: string;
  label: string;
};

const CompanyDetails: FC<StartupInputProps> = ({
  modalIsOpen,
  closeModal,
  name,
  location,
  industrySelected,
  employeeCountSelected,
  stageSelected,
  websiteUrl,
  userId,
}) => {
  const startupId = userId?.toString() || "";
  const [industries, setIndustries] = useState<any[]>([])
  const [industry, setIndustry] = useState<IndustriesSelector>(industrySelected ?? {id: '', name: ''});
  const [employees, setEmployees] = useState<EmployeeSelector>({
    label: `${employeeCountSelected}`,
    value: `${employeeCountSelected}`,
  });
  const [stage, setStage] = useState<StageSelector>({
    label: `${stageSelected}`,
    value: `${stageSelected}`,
  });

  const initialValues: StartupFormProfileProps = {
    name: name || "",
    description: "",
    location: location || "",
    industry: [{ id: `${industrySelected}`, name: `${industrySelected}` }],
    employeeCount: [
      { label: `${employeeCountSelected}`, value: `${employeeCountSelected}` },
    ],
    stage: [{ label: `${stageSelected}`, value: `${stageSelected}` }],
    websiteUrl: websiteUrl || "",
  };

  const handleCreateStartup = async (values: StartupFormProfileProps) => {
    try {
      const response = await editStartupProfile(
        {
          name: values.name,
          description: null,
          location: values.location,
          industry: industry.id,
          employeeCount: employees.value,
          stage: stage.value,
          websiteUrl: values.websiteUrl,
        },
        startupId
      );
      if (response) {
        Swal.fire({
          title: "Success",
          text: "Amazing you've created startup profile",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "swal2-confirm btn btn-primary",
          },
        });
        closeModal();
      }
    } catch (error) {
      Swal.fire({
        title: "Oops",
        text: `Looks like there is something wrong <br />
          `,
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "swal2-confirm btn btn-primary",
        },
      });
    }
  };

  const handleIndustry = (option: IndustriesSelector) => {
    return setIndustry(option);
  };

  const handleInputEmployees = (option: EmployeeSelector) => {
    return setEmployees(option);
  };

  const handleInputStage = (option: StageSelector) => {
    return setStage(option);
  };

  useEffect(() => {
    const getIndustriesData = async () => {
      const res = await getIndustries()
      setIndustries(res)
    }
    getIndustriesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Choice Modal"
      ariaHideApp={false}
    >
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={"1rem"}
        color={"white"}
        bgcolor={"#012626"}
      >
        <Box>
          <p className="buildProfile__modal_paragraph">Company Details</p>
        </Box>
        <Box>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "10px",
            }}
            onClick={closeModal}
          >
            <LiaTimesSolid size={32} color="#04BF7B" />
          </button>
        </Box>
      </Grid>
      <Grid
        p={4}
        container
        display="flex"
        direction="column"
        alignItems="stretch"
      >
        <Box p={1}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Formik
              initialValues={initialValues}
              onSubmit={(values: StartupFormProfileProps) => {
                handleCreateStartup(values);
              }}
            >
              {(props) => (
                <>
                  <Grid item md={12} pt={4}>
                    <Form>
                      <div className="startupProfile__input">
                        <label htmlFor="name">Startup name</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="e.g. Unicorn Inc."
                          style={{ width: "100%" }}
                          className="form-control mb-3"
                        />
                        <ErrorMessage
                          name="name"
                          component="p"
                          className="requiredField"
                        />
                        <label htmlFor="location">Location</label>
                        <Field
                          type="location"
                          name="location"
                          placeholder="e.g. San Francisco, CA"
                          style={{ width: "100%" }}
                          className="form-control mb-3"
                        />
                        <ErrorMessage
                          name="location"
                          component="p"
                          className="requiredField"
                        />
                        <label>Industry</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={industry}
                          options={industries}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          onChange={(option) =>
                            handleIndustry(option as IndustriesSelector)
                          }
                        />

                        <label>Employees number</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={employees}
                          options={EmployeeCollection}
                          onChange={(option) =>
                            handleInputEmployees(option as EmployeeSelector)
                          }
                        />

                        <label>Stage</label>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderRadius: "10px",
                              border: "1px solid #d1d1d1",
                              height: "53px",
                              marginTop: "10px",
                              marginBottom: "16px",
                              alignContent: "center",
                            }),
                          }}
                          value={stage}
                          options={StageCollection}
                          onChange={(option) =>
                            handleInputStage(option as StageSelector)
                          }
                        />
                        <label htmlFor="email">Website</label>
                        <Field
                          type="text"
                          name="websiteUrl"
                          placeholder="e.g. www.unicorn.com"
                          style={{ width: "100%" }}
                          className="form-control mb-3"
                        />
                        <ErrorMessage
                          name="websiteUrl"
                          component="p"
                          className="requiredField"
                        />
                      </div>
                      <Grid
                        item
                        display={"flex"}
                        justifyContent="center"
                        mt={6}
                        md={12}
                      >
                        <button
                          type="submit"
                          className="button__navigation color__active"
                        >
                          Save
                        </button>
                      </Grid>
                    </Form>
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CompanyDetails;
